import React from "react";
import "../OldCss/home.css";

function Home() {
    return (
        <div className="flex flex-col items-center justify-center w-full h-screen bg-black font-sans">
            <h1 className="homepageH1 text-4xl md:text-9xl text-center font-[Poiret_One] text-white leading-none">
                SHADOW GAMES
            </h1>
            <div className="flex flex-row sm:flex-row w-full max-w-md justify-center items-center sm:justify-between sm:mt-8 mt-4 gap-4">
                <a
                    href="/shadowai"
                    className="text-xl ml-4 sm:text-5xl py-2 px-4 w-1/2 sm:w-1/2 bg-black text-white font-[Rajdhani] rounded-lg text-center no-underline border border-white"
                >
                    AI
                </a>
                <a
                    href="/games"
                    className="text-xl mr-4 sm:text-5xl py-2 px-4 w-1/2 sm:w-1/2 bg-black text-white font-[Rajdhani] rounded-lg text-center no-underline border border-white"
                >
                    GAMES
                </a>
            </div>
        </div>
    );
}

export default Home;
