// === PAGES ===
import Home from './Pages/HomePage.jsx';
import Games from './Pages/GamesPage.jsx';
import TalkAi from './Components/ShadowAi/talkAi/talkAi.jsx';
import CharacterMenu from './Pages/CharacterMenu.jsx';
// import RenPyEmbed from './Pages/RenPage.jsx';

// === GAMES ===
import Snake from './Games/Snake/SnakeComponent';
import Meme from './Games/MemeGenerator/MemeGenerator';
import Tictactoe from './Games/Tictactoe/Tictactoe';
import ImmortalCube from './Games/ImmortalCube/ImmortalCube';


// === ROUTER ===
const routes = [
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/games",
        element: <Games />,
    },
    {
        path: "/snake",
        element: <Snake />,
    },
    {
        path: "/memegenerator",
        element: <Meme />,
    },
    {
        path: "/tictactoe",
        element: <Tictactoe />,
    },
    {
        path: "/immortalcube",
        element: <ImmortalCube />,
    },
    {
        path: "/shadowai/aichat",
        element: <TalkAi />,
    },
    {
        path: "/shadowai",
        element: <CharacterMenu />,
    },
];



export default routes;