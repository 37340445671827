import React from "react";
import './header.css';
import {MiniHeader} from "./phoneHeader.js"

// import logo from "../../Shared/images/web_logo_white.png" 
const miniHeader = new MiniHeader();


export default class Header extends React.Component {
    
    componentDidMount() {
        miniHeader.startGame()
    }
    
    
    render() {
        return (
            <>
                <header>
                    <div className="topHeader">
                        <div className="leftHeader">
                             <img src="./images/web_logo_white.png" alt="" className="logoImage"/>
                            <a href="/" className="logo">SHADOW GAMES</a>
                        </div>

                        <div className="rightHeader" id="rightHeader">
                            <a href="/shadowai">AI</a>
                            <a href="/games">GAMES</a>
                        </div>

                        <button className="menuHeaderButton" id="menuHeaderButton"><span className="material-symbols-outlined">menu</span></button>
                    </div>

                    <div className="bottomHeader" id="bottomHeader">
                        <a href="/shadowai">AI</a>
                        <a href="/games">GAMES</a>
                        {/* <a href="/about">ABOUT</a>
                        <a href="/sign-in">ACCOUNT</a> */}
                    </div>
                </header>

            </>
        )
    }
}