import React from "react";
import Header from "../Components/Header/Header.jsx";

import "../OldCss/games.css"
// import Tsk_pfp from "../../Shared/images/tsk_pfp.png";
// import Snake_pfp from "../../Shared/images/snake_pfp.png";
// import Meme_pfp from "../../Shared/images/meme_pfp.png";
// import Tictactoe_pfp from "../../Shared/images/tictactoe_pfp.jpg";
// import ImmortalCube_pfp from "../../Shared/images/cube_pfp.jpg";


const games = [
    {
        active: true,
        link: "/immortalcube",
        image: "./images/cube_pfp.jpg",
        title: "Immortal Cube",
        description: "The cube that never really dies",
        stars: ["checked", "checked", "checked", "checked", "checked"]
    },
    { 
        active: true,
        link: "/snake",
        image: "./images/snake_pfp.png",
        title: "Snake",
        description: "A long, skinny creature that looks like a snake",
        stars: ["checked", "checked", "checked", "", ""]
    },
    // {
    //     active: false,
    //     link: "the_school_king",
    //     image: Tsk_pfp,
    //     title: "The School King",
    //     description: "A story about how a nobody became The School King",
    //     stars: ["checked", "checked", "checked", "", ""]

    // },
    {
        active: true,
        link: "/memegenerator",
        image: "./images/meme_pfp.png",
        title: "Meme Generator",
        description: "A button that can give you thousands of memes",
        stars: ["checked", "checked", "checked", "checked", ""]

    },
    {
        active: true,
        link: "/tictactoe",
        image: "./images/tictactoe_pfp.jpg",
        title: "Tic Tac Toe",
        description: "Ummmm... its Tic Tac Toe",
        stars: ["checked", "checked", "checked", "", ""]
    },

]  



export default function Games() {
  return (
    <>

        <div className="gamesPage">
        <Header />

            <ul>
                {games.map((game, i) => {
                    if (!game.active) return null
                    return (
                        <li key={i}> 
                            <a href={game.link} className="gameHolder">
                                    <img src={game.image} alt="opps" />
                                    <div className="underImage">
                                        <div className="bottomText">
                                            <h2>{game.title}</h2>
                                            <div className="stars">
                                                <span className={game.stars[0] +  " material-symbols-outlined"}>star</span>
                                                <span className={game.stars[1] +  " material-symbols-outlined"}>star</span>
                                                <span className={game.stars[2] +  " material-symbols-outlined"}>star</span>
                                                <span className={game.stars[3] +  " material-symbols-outlined"}>star</span>
                                                <span className={game.stars[4] +  " material-symbols-outlined"}>star</span>
                                            </div>
                                        </div>
                                        {/* <div className="description">
                                            <h4>{game.description}</h4>
                                        </div> */}
                                    </div>
                            </a>
                        </li>   
                    )
                })}
            </ul>
        </div>
    </>
  )
}