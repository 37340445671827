import React from "react";
import Header from "../../Components/Header/Header.jsx";
import MobileBlock from "../../Components/Mobile/MobileBlock.jsx";

import "./snake.css";
import {SnakeGame} from "./snake_script.js";

import startSS from "./images/snake.jpg"
import endSS from "./images/game_over.jpg"


const snakeGame = new SnakeGame();

export default class Snake extends React.Component {
    
    componentDidMount() {
        snakeGame.startGame()
    }
  
    render() {
        return (
            <>
                <MobileBlock />

        
                <div className="snakeGame">

                   <Header />

                    <div className="main_collum">
                        <div className="score_counter">
                            <h1>SNAKE</h1>
                            <div className="scores">
                                <p id="score_display">Score: 0</p>
                                <p id="high_score">High Score: 0</p>
                            </div>
                            
                        </div>
        
                        <div className="main" id="main">
                            <img id="start_image" src={startSS} alt="opps" />
                            <img id="end_image" className="hide" src={endSS} alt="opps" />
                            <table id="mainTable"></table>
                        </div> 
        
                        <div className="buttons">
                            <button id="restart" className="restart">Restart</button>
                            <button id="settingsButton" className="settingsButton">Settings</button>
                            <button id="changeColor" className="change_color">COLOR</button>
                        </div>
        
                        <div className="settingsHolder"></div>
                    </div>
        
                    <div className="settings hide" id="snakeSettings">
                        <span className="material-symbols-outlined closeButton" id="closeButton">close</span>
                        <div className="gameRule" id="speed">
                        <h2>Speed</h2>
                        <div className="plusmin">
                            <span className="material-symbols-outlined" id="minspeed">arrow_back_ios</span>
                            <p id="speednum">0</p>
                            <span className="material-symbols-outlined" id="maxspeed">arrow_forward_ios</span>
                        </div>
                        </div>
                        <div className="gameRule">
                        <h2>Number of Food</h2>
                        <div className="plusmin">
                            <span className="material-symbols-outlined" id="minfood">arrow_back_ios</span>
                            <p id="foodnum">0</p>
                            <span className="material-symbols-outlined" id="maxfood">arrow_forward_ios</span>
                        </div>
                        </div>
                        <div className="gameRule">
                        <h2>Number of Blocks</h2>
                        <div className="plusmin">
                            <span className="material-symbols-outlined" id="minblock">arrow_back_ios</span>
                            <p id="blocknum">0</p>
                            <span className="material-symbols-outlined" id="maxblock">arrow_forward_ios</span>
                        </div>
                        </div>
        
                        <p className="settingsInfo">Restart the game to update settings</p>
                    </div>
                </div>
        
            </>
        )
    }
  
}