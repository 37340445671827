import React from "react";
import { useNavigate } from "react-router-dom";
import { characters } from "../Components/ShadowAi/characterList";

export default function CharacterMenu() {
    const navigate = useNavigate();
    function goto(tag: string): void {     
        navigate('/shadowai/aichat', { state: { tag } });
    }

    let categoryList = [];
    for (let i = 0; i < characters.length; i++) {
        if (!categoryList.includes(characters[i].category)) categoryList.push(characters[i].category)
    }

    return ( 
        <div className="characterMenuPage bg-[#F3EFE0] flex flex-col items-center justify-start text-[#434242] h-auto">
            <a className="backButton absolute top-0 left-0 w-[370px] h-[70px] text-[25px] border-[5px] border-l-0 border-t-0 border-[#434242] bg-[#434242] text-[#F3EFE0] rounded-br-[25px] flex justify-center items-center no-underline" href="/">
                <span className="material-symbols-outlined mr-[5px] text-[30px] mb-[2px]">arrow_back</span>Back to Shadow Games
            </a>
            
            <h1 className="text-[100px] text-[#434242] mt-[40px] mb-[-20px] absolute right-[5%]">SHADOW AI</h1>
            <div className="characterSelection flex flex-col items-center justify-start w-full mt-[100px]">
                {categoryList.map((category, j) => {
                    return (
                        <>
                            <h2 className="w-[90%] text-left uppercase text-[50px] text-[#434242]">{category}</h2>
                            <div className="line w-[90%] h-[10px] bg-gradient-to-r from-[#434242] to-[#F3EFE0] rounded-[25px] mb-[20px]"></div>
                            
                            <div className="categoryHolder flex w-[90%] flex-row flex-wrap justify-start items-center mb-[20px]" key={j}>
                                {characters.map((character, i) => {
                                    if (character.category === category) {
                                        return (
                                            <div className="itemHolder w-[24%] mx-[0.5%] mb-[20px]" key={i}> 
                                                <button onClick={() => goto(character.name)} className="item w-full h-[600px] flex flex-col items-center justify-start rounded-[25px] bg-gradient-to-r from-[#434242] to-[#7A7870]">
                                                    <img src={character.image} alt="opps" className="w-full h-[500px] object-cover object-top rounded-t-[20px]" />
                                                    <div className="underImage flex h-[100px] items-center justify-center">
                                                        <div className="bottomText">
                                                            <h2 className="text-[#F3EFE0] text-4xl">{character.name}</h2>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>   
                                        )
                                    } else {
                                        return <></>
                                    }
                                })}
                            </div> 
                        </>
                    )
                })}
            </div>
        </div>
    )
}
